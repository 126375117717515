/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
//import React, { Suspense } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import Login from './views/login/Login';
import ModalSesion from './layouts/modal/ModalSession';
import DetallesUsuario from './views/details/DetallesUsuario';
import { AuthContext } from './context/authContext';
import loginApiService from './services/http/loginApiService';
/*import Loader from './layouts/loader/Loader';
import FullLayout from './layouts/FullLayout'*/

const App = () => {
  const { logoutGoogle } = useContext(AuthContext);
  const userSession = JSON.parse(localStorage.getItem('user'));
  const [isVerified, setIsVerified] = useState(1);

  let render = null;

  const onLoad = (verified) => {
    if (localStorage.getItem('user') === null) {
      render = <Login />;
    } else if (userSession !== null) {
      if (verified === 0) {
        logoutGoogle();
        render = (
          <>
            <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
            <Login />
          </>
        );
        localStorage.removeItem('user');
        localStorage.removeItem('student');
      } else if (JSON.parse(localStorage.getItem('error')) !== null) {
        logoutGoogle();
        render = (
          <>
            <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
            <Login />
          </>
        );
      } else {
        render = <DetallesUsuario />;
      }
    }

    return render;
  };

  const verificarTokenDeUsuario = async () => {
    try {
      await loginApiService.verifyToken();
      setIsVerified(1);
    } catch (error) {
      setIsVerified(0);
    }
  };

  useEffect(() => {
    if (userSession !== null) {
      verificarTokenDeUsuario();
    }
  }, []);

  return <>{onLoad(isVerified)}</>;
};

export default App;
