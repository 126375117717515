import axios from 'axios';
import generalCatchError from './generalCatchError';

class HttpService {
  constructor(baseUrl = '', generalCatch = generalCatchError) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    });
    this.generalCatch = generalCatch;
  }

  async get(url, options = {}) {
    try {
      const apiKey = JSON.parse(localStorage.getItem('user'))?.tokenId;
      const userEmail = JSON.parse(localStorage.getItem('user'))?.profileObj?.email;

      const response = await this.axiosInstance.get(url, {
        ...options,
        headers: {
          Authorization: `Bearer ${apiKey}`,
          ...options.headers,
        },
        params: {
          userEmail,
          ...options.params,
        },
      });
      return response.data;
    } catch (error) {
      this.generalCatch(error);
      throw error;
    }
  }

  async post(url, data, options = {}) {
    try {
      const apiKey = JSON.parse(localStorage.getItem('user'))?.tokenId;
      const userEmail = JSON.parse(localStorage.getItem('user'))?.profileObj?.email;

      const response = await this.axiosInstance.post(
        url,
        {
          userEmail,
          ...data,
        },
        {
          ...options,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            ...options.headers,
          },
          params: {
            userEmail,
            ...options.params,
          },
        },
      );
      return response.data;
    } catch (error) {
      this.generalCatch(error);
      throw error;
    }
  }

  async put(url, data, options = {}) {
    try {
      const apiKey = JSON.parse(localStorage.getItem('user'))?.tokenId;
      const userEmail = JSON.parse(localStorage.getItem('user'))?.profileObj?.email;

      const response = await this.axiosInstance.put(
        url,
        {
          userEmail,
          ...data,
        },
        {
          ...options,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            ...options.headers,
          },
          params: {
            userEmail,
            ...options.params,
          },
        },
      );
      return response.data;
    } catch (error) {
      this.generalCatch(error);
      throw error;
    }
  }

  async patch(url, data, options = {}) {
    try {
      const apiKey = JSON.parse(localStorage.getItem('user'))?.tokenId;
      const userEmail = JSON.parse(localStorage.getItem('user'))?.profileObj?.email;

      const response = await this.axiosInstance.patch(
        url,
        {
          userEmail,
          ...data,
        },
        {
          ...options,
          headers: {
            Authorization: `Bearer ${apiKey}`,
            ...options.headers,
          },
          params: {
            userEmail,
            ...options.params,
          },
        },
      );
      return response.data;
    } catch (error) {
      this.generalCatch(error);
      throw error;
    }
  }

  async delete(url, options = {}) {
    try {
      const apiKey = JSON.parse(localStorage.getItem('user'))?.tokenId;
      const userEmail = JSON.parse(localStorage.getItem('user'))?.profileObj?.email;

      await this.axiosInstance.delete(url, {
        ...options,
        headers: {
          Authorization: `Bearer ${apiKey}`,
          ...options.headers,
        },
        params: {
          userEmail,
          ...options.params,
        },
      });
    } catch (error) {
      this.generalCatch(error);
      throw error;
    }
  }
}

export const httpServiceInstanceNode = new HttpService(process.env.REACT_APP_NODE_API);
export default HttpService;
